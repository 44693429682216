.headerBar{
  width: 100%;
  height: 100%;
  display: flex;
  .iconBlock{
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 0.1s;
    overflow: hidden;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    font-weight: 900;
    img{
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
  }
  .menuBlock{
    height: 100%;    
    border-bottom: 1px solid #dddddd;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.1s;
    .leftSelect{
      display: flex;
      align-items: center;
      position: absolute;
      height: 50%;
      display: flex;
      .menuButton{
        cursor: pointer;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: '#DDDDDD'
        }
      }
    }
    .rightSelect{
      display: flex;
      align-items: center;
      position: absolute;
      right: 2%;
      height: 50%;
      display: flex;
      padding-left: 16px;
    }
  }
}