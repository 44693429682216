.wrapper{
  padding: 0 1rem;
  .header{
    padding: 5rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.625rem;
    .icon {
      width: 9.5rem;
      max-width: 9.5rem;
    }
    .icon_text{
      color: #613DCC;
      font-size: 1.5rem;
      font-weight: 900;
    }
  }
  .login_box{
    height: 20.25rem;
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    position: relative;
    .form_item{
      height: 30%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 0.3rem;
      .form_label{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #333333;
      }
      .form_input{
        height: 3.125rem;
        border-radius: 0.25rem;
        border: 1px solid #D9DAEA;
        padding: 0 0 0 1.25rem;
        outline-color: #9f55ff;
        font-size: 1rem;
      }
    }
    .pass_change_link{
      font-weight: 400;
      color: #613DCC;
      font-size: 0.875rem;
      margin: 0.875rem 0;
      text-align: right;
      text-decoration: underline;
    }
    .login_btn{
      background: #613dcc;
      padding: 1rem;
      border-radius: 1.75rem;
      font-size: 1rem;
      font-weight: 700;
      color: #FFFFFF;
      text-align: center;
    }
    .login_btn:active{
      background-color: #512da8;
    }
  }
}
