.box{
  width: 100%;
  height: calc(100vh - 20px);
  box-sizing: border-box;
  .headerBar {
    width: 100%;
    height: 56px;
  }
  .bodyMain {
    width: 100%;
    display: flex;
    .menu {
      transition: all 0.1s;
      overflow: hidden;
    }
    .content {
      height: calc(100vh - 56px);
      transition: all 0.1s;
      overflow-y: scroll;
      overflow-y: overlay;
    }
  }

}