.nav_name{
  width: 100%;
  height: 28px;
  line-height: 28px;
  color: #333333;
  font-size: 12px;
  background-color: #FFFFFF;
  padding-left: 20px;
}
.ground {
  width: 100%;
  user-select: none;
  position: relative;
  padding: 24px;
  background-color: #eaf6fd;
  .nav_title{
    color: #333333;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 24px;
    position: relative;
  }
  .nav_title::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 24px;
    height: 2px;
    background-color: #0068B5;
  }
  .searchArea {
    width: 100%;
    display: flex;
    align-items: center;
    .searchAreaLabel {
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      margin-right: 5px;
    }
    .input {
      width: 128px;
      height: 30px;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding-left: 20px;
      color: #AAAAAA;
      font-size: 13px;
      margin-right: 16px;
    }
    .select {
      width: 128px;
      height: 30px;
      border: 1px solid #DDDDDD;
      background-color: #FFFFFF;
      color: #AAAAAA;
      font-size: 13px;
      margin-right: 30px;
    }
    .placeholder {
      color: #dbdbdb;
      font-size: 13px
    }
    .menuItem {
      height: 30px;
      font-size: 13px;
      color: #AAAAAA;
    }
    .button {
      width: 94px;
      height: 30px;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 14px;
      background-color: #2673BF;
    }
  }
  .contentArea {
    width: 100%;
    margin-top: 20px;
    .subject_tabs{
      display: flex;
      align-items: center;
      .subject_tab{
        width: 68px;
        height: 28px;
        line-height: 28px;
        border-radius: 4px 4px 0 0;
        margin-right: 1px;
        background-color: #CCCCCC;
        color:#FFFFFF;
        font-weight: 700;
        font-size: 16px;
        text-align: center;        
        letter-spacing: 4px;
      }
    }
    .tabs {
      display:flex;
      align-items: center;
      .tab {
        width: 108px;
        height: 40px;
        background-color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        border-top: 4px solid #CCCCCCCC;
        cursor: pointer;
        margin-right: 2px;
      }
      .tabSelect {
        border-top-color: #2673BF;
        span{
          color: #333333;
        }
      }
      .tabRight {
        width: 162px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        font-weight: 700;
        font-size: 14px;
        color: #CCCCCC;
      }
    }
  }
  .tableHeaderCell {
    min-width: 96px;
    background-color: #194C80;
    color: #FFFFFF;
    font-weight: 700;
    border-right: 1px solid #FFFFFF;
    text-align: center;
    padding: 12px;
  }
  .tableBodyCell {
    font-size: 12px;
    color: #333333;
    border-right: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
  }
  .tableCellGreen {
    background-color: #adf7d8;
  }
  .tableCellYellow {
    background-color: #fff1a6;
  }
  .tableCellPink {
    background-color: #ffcdcd;
  }
  .tableCellWhite {
    background-color: #FFFFFF;
  }
}