.menuLink{
  text-decoration: none;
  .menuItem {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDD;
    position: relative;
    & > div:nth-child(1) {
        margin-left: 16px;
        width: 6px;
        height: 20px;
    }
    & > div:nth-child(2) {
        margin-left: 16px;
        font-size: 16px;
        color: black;
    }
}
}